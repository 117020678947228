( function( $ ) {

	
	$(document).ready(function (){
	
	});
	
	
	/* optional triggers
	
	$(window).load(function() {
		
	});
	
	$(window).resize(function() {
	
	});
	
	*/


} )( jQuery );